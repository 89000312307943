<template>
    <div id='special'>
        <el-card>
            <el-row :gutter="20" class="operate">
                <el-col :span="4">
                    <el-select v-model="selectType" placeholder="请选择专题类型" filterable clearable>
                        <el-option v-for="(item, index) in types" :key="index" :label="item.name" :value="item.value"></el-option>
                    </el-select>
                </el-col>
                <el-col :span="4">
                    <el-input v-model="selectName" placeholder="请输入专题名称" clearable></el-input>
                </el-col>
                <el-button @click="search" icon="el-icon-search" type="primary"></el-button>
                <el-button @click="openDialog(form, true)" type="primary">添加专题</el-button>
            </el-row>
            <el-table v-loading="loading" :data="tableData" style="width: 100%" stripe border>
                <el-table-column fixed="left" prop="id" label="ID" width="100"></el-table-column>
                <el-table-column label="Banner" width="150" class-name="avatar">
                    <template #default="scope"><el-avatar :size="40" :src="scope.row.banner"></el-avatar></template>
                </el-table-column>
                <el-table-column prop="name" label="名称" width="200"></el-table-column>
                <el-table-column prop="type_name" label="类型" width="150"></el-table-column>
                <el-table-column prop="desc" label="描述"></el-table-column>
                <el-table-column label="状态" width="200">
                    <template slot-scope="scope">
                        <el-switch v-model="scope.row.status" @change="changeStatus(scope.row)" :active-value="1" :inactive-value="0"></el-switch><span class="status_text">{{scope.row.status | statusText}}</span>
                    </template>
                </el-table-column>
                <el-table-column label="排序" width="200">
                    <template slot-scope="scope">
                        <el-input-number v-model="scope.row.sort" @change="changeSort(scope.row)" :controls="false" :min="0" size="small"></el-input-number>
                    </template>
                </el-table-column>
                <el-table-column fixed="right" label="操作" width="300">
                    <template #default="scope">
                        <el-button @click="queryItem(scope.row.id)" type="primary" size="mini">查看</el-button>
                        <el-button @click="openAssocDialog(scope.row, false)" type="primary" size="mini">关联</el-button>
                        <el-button @click="openDialog(scope.row, false)" type="warning" size="mini">修改</el-button>
                        <el-button @click="deleteItem(scope.row.id)" type="danger" size="mini">删除</el-button>
                    </template>
                </el-table-column>
            </el-table>

            <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="params.page" :page-sizes="[5, 10, 20, 50, 100, 200]" :page-size="params.page_size" layout="total, sizes, prev, pager, next, jumper" :total="total">
            </el-pagination>

            <!-- 添加弹窗 -->
            <el-dialog @close="closeDialog('addSpecialItem')" title="添加专题" :visible.sync="showDialog" class="add_dialog">
                <el-form :model="form" ref="addSpecialItem" :rules="rules">
                    <el-form-item label="名称" prop="name" :label-width="formLabelWidth">
                        <el-col :span="12">
                            <el-input v-model="form.name" autocomplete="off"></el-input>
                        </el-col>
                    </el-form-item>
                    <el-form-item label="类型" prop="type_name" :label-width="formLabelWidth">
                        <el-select v-model="form.type" placeholder="请选择类型" filterable clearable>
                            <el-option v-for="(item, index) in types" :key="index" :label="item.name" :value="item.value"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="Banner" :label-width="formLabelWidth">
                        <el-upload :action="baseURL" :show-file-list="false" :on-success="handleBannerSuccess" :before-upload="beforeUpload" class="avatar-uploader">
                            <img v-if="form.banner" :src="form.banner" class="avatar">
                            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                        </el-upload>
                    </el-form-item>
                    <el-form-item label="宽度" prop="width" :label-width="formLabelWidth">
                        <el-col :span="12">
                            <el-input v-model="form.width" autocomplete="off"></el-input>
                        </el-col>
                    </el-form-item>
                    <el-form-item label="高度" prop="height" :label-width="formLabelWidth">
                        <el-col :span="12">
                            <el-input v-model="form.height" autocomplete="off"></el-input>
                        </el-col>
                    </el-form-item>
                    <el-form-item label="描述" prop="desc" :label-width="formLabelWidth">
                        <el-input type="textarea" v-model="form.desc"></el-input>
                    </el-form-item>
                    <el-form-item label="状态" prop="status" :label-width="formLabelWidth">
                        <el-switch v-model="form.status" :active-value="1" :inactive-value="0"></el-switch><span class="status_text">{{form.status | statusText}}</span>
                    </el-form-item>
                    <el-form-item label="排序" prop="sort" :label-width="formLabelWidth">
                        <el-col :span="8">
                            <el-input-number v-model="form.sort" autocomplete="off" :min="0" :controls="false"></el-input-number>
                        </el-col>
                    </el-form-item>
                </el-form>
                <div slot="footer" class="dialog-footer">
                    <el-button @click="cancel">取 消</el-button>
                    <el-button v-if="isAddFlag" @click="addSpecial('addSpecialItem')" type="primary">确 定</el-button>
                    <el-button v-else @click="editItem('addSpecialItem')" type="primary">确 定</el-button>
                </div>
            </el-dialog>

            <el-dialog @close="cancelAssoc" :title="assocSpecial | assocTitle" :visible.sync="showAssocDialog" class="assoc_dialog">
                <el-row :gutter="20" class="operate">
                    <template v-if="assocSpecial.type == 1">
                        <el-col :span="4">
                            <el-select v-model="assocParam.column_id" placeholder="请选择栏目" filterable clearable>
                                <el-option v-for="(item, index) in columnList" :key="index" :label="item.name" :value="item.id"></el-option>
                            </el-select>
                        </el-col>
                        <el-col :span="4">
                            <el-input v-model="assocParam.title" placeholder="请输入文章标题" clearable></el-input>
                        </el-col>
                    </template>
                    <template v-else>
                        <el-col :span="4">
                            <el-cascader
                                    v-model="selectCate"
                                    :options="cateList"
                                    :props="{ expandTrigger: 'hover', checkStrictly: true }"
                                    size="medium" placeholder="请选择分类" filterable clearable></el-cascader>
                        </el-col>
                        <el-col :span="4">
                            <el-input v-model="assocParam.book_name" placeholder="请输入书籍名称" clearable></el-input>
                        </el-col>
                    </template>
                    <el-col :span="4">
                        <el-select v-model="queryType" placeholder="查询方式" filterable>
                            <el-option label="所有数据" value="all"></el-option>
                            <el-option label="已关联数据" value="assoc"></el-option>
                        </el-select>
                    </el-col>
                    <el-button @click="searchAssoc" icon="el-icon-search" type="primary"></el-button>
                    <el-button @click="handleAssoc" type="primary">关联</el-button>
                </el-row>
                <el-table ref="assocTable" v-loading="assocLoading" :data="assocData" :row-key="getRowKey" style="width: 100%" stripe border @selection-change="handleAssocSelection">
                    <el-table-column fixed="left" type="selection" :reserve-selection="true" width="55"></el-table-column>
                    <el-table-column fixed="left" prop="id" label="ID" width="100"></el-table-column>
                    <template v-if="assocSpecial.type === 1">
                        <el-table-column prop="title" label="标题"></el-table-column>
                        <el-table-column prop="column_name" label="栏目" width="150"></el-table-column>
                        <el-table-column prop="tag" label="标签" width="150"></el-table-column>
                    </template>
                    <template v-else>
                        <el-table-column prop="book_name" label="书籍名称"></el-table-column>
                        <el-table-column prop="author_name" label="作者姓名" width="150"></el-table-column>
                        <el-table-column prop="cat_name" label="分类名称" width="150"></el-table-column>
                    </template>
                    <el-table-column label="状态" width="150">
                        <template slot-scope="scope">
                            <span class="status_text">{{scope.row.status | statusText}}</span>
                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination @size-change="handleAssocPageSizeChange" @current-change="handleAssocPageChange" :current-page="assocParam.page" :page-sizes="[5, 10, 20, 50, 100, 200]" :page-size="assocParam.page_size" layout="total, sizes, prev, pager, next, jumper" :total="assocTotal">
                </el-pagination>
            </el-dialog>

            <!-- 查看弹窗 -->
            <el-dialog title="详情" :visible.sync="checkItem" center class="check_dialog">
                <div class="check_div">
                    <div class="check_label">名称：</div>
                    <div class="check_data">{{checkData.name}}</div>
                </div>
                <div class="check_div">
                    <div class="check_label">类型：</div>
                    <div class="check_data">{{checkData.type_name}}</div>
                </div>
                <div class="check_div">
                    <div class="check_label">Banner：</div>
                    <div class="check_data"><el-avatar :src="checkData.banner"></el-avatar></div>
                </div>
                <div class="check_div">
                    <div class="check_label">描述：</div>
                    <div class="check_data">{{checkData.desc}}</div>
                </div>
                <span slot="footer" class="dialog-footer">
          <el-button type="primary" @click="checkItem = false">确 定</el-button>
        </span>
            </el-dialog>
        </el-card>
    </div>
</template>

<script>
    import {getSpecialList, addSpecialItem, getSpecialAll, getArticleColumns, getArticleList, getCateCascade, getBookList, querySpecialItem, editSpecialItem, specialAssoc, specialAssocList, deleteSpecialItem} from '@api';
    export default {
        name: 'Special',
        components: {},
        data() {
            return {
                params: { // 请求分页参数
                    page: 1,
                    page_size: 10
                },
                assocParam: {
                    page: 1,
                    page_size: 10,
                    ids: [],
                    title: '', // 搜索文章标题
                    column_id: '', // 搜索的文章栏目,
                    book_name: '', // 搜索的书籍名称
                    cat_id: '', // 搜索的书籍分类
                },
                tableData: [], // 表格数据
                assocData: [], // 关联页面表格数据
                loading: true, // 表格加载等待
                assocLoading: true, // 关联页面数据加载等待
                showDialog: false, // 是否显示弹窗
                showAssocDialog: false, // 是否显示关联页面弹窗
                isAddFlag: false, // 是否点击添加用户
                types: [{name: '文章', value: 1}, {name: '小说', value: 2}],
                form: { // 添加表单
                    name: '',
                    type: '',
                    banner: '',
                    width: '',
                    height: '',
                    banner_change: false,
                    desc: '',
                    status: 0,
                    sort: 0,
                },
                initForm: {
                    name: '',
                    type: '',
                    banner: '',
                    width: '',
                    height: '',
                    banner_change: false,
                    desc: '',
                    status: 0,
                    sort: 0,
                },
                rules: {
                    name: [
                        {required: true, message: '请输入标题', trigger: 'blur'}
                    ]
                },
                columnList: [], // 栏目列表
                cateList: [],   // 分类列表
                baseURL: `${this.$store.state.config.baseURL}/upload`, // 上传图片地址
                formLabelWidth: '120px', // from表单label的宽度
                total: 0, // 数据总条数
                assocTotal: 0, // 关联资源页面数据总数
                checkItem: false, // 查看每条的弹窗
                checkData: {}, // 查询每条时的数据
                selectName: '', // 查询专题标题
                selectType: '', // 查询专题类型
                queryType: 'all', // 查询方式
                srcList: [''], // 查看图片
                assocSpecial: {},
                assocSelection: [],
                assocSelectionIds: [],
                selectCate: [],
            }
        },
        created() {
            this.getData(this.params);
        },
        filters: {
            statusText(status) {
                if (status > 0) {
                    return '已审核';
                }
                return '未审核';
            },
            assocTitle(special) {
                return "关联" + special.type_name;
            }
        },
        methods:{
            search() { // 搜索
                const {selectName, selectType} = this;
                this.params.name = selectName;
                this.params.type = selectType;
                this.getData(this.params);
            },
            addSpecial(refName) { // 添加
                this.$refs[refName].validate(valid => {
                    if (!valid) return false;
                    this.form.type = parseInt(this.form.type);
                    this.form.status = parseInt(this.form.status);
                    this.form.width = parseInt(this.form.width);
                    this.form.height = parseInt(this.form.height);
                    addSpecialItem(this.form).then(() => {
                        this.showDialog = false;
                        this.getData(this.params);
                        this.$message.success('添加成功!');
                        this.initFormData();
                    });
                });
            },
            queryItem(id) { // 查询单条数据
                this.checkItem = true;
                this.checkData = {};
                querySpecialItem(id).then(res => this.checkData = res.data);
            },
            editItem(refName) { // 修改单条数据
                this.$refs[refName].validate(valid => {
                    if (!valid) return false;
                    const {form, form: {id}} = this;
                    const body = form;
                    body.type = parseInt(body.type);
                    body.status = parseInt(body.status);
                    form.width = parseInt(form.width);
                    form.height = parseInt(form.height);
                    body.fields = Object.keys(this.initForm);
                    editSpecialItem(id, body).then(() => {
                        this.showDialog = false;
                        this.$message.success('修改成功！');
                        this.getData(this.params);
                    });
                });
            },
            deleteItem(id) { // 删除单挑数据
                this.$confirm('此操作将永久删除, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    deleteSpecialItem(id).then(() => {
                        this.$message.success('删除成功!');
                        this.getData(this.params);
                    });
                }).catch(() => this.$message.info('已取消删除'));
            },
            changeStatus(item) {
                item.fields = ["status"];
                item.status = item.status ? 1 : 0;
                editSpecialItem(item.id, item).then(() => {
                    this.$message.success('修改成功！');
                    this.getData(this.params);
                });
            },
            changeSort(item) {
                item.fields = ["sort"];
                editSpecialItem(item.id, item).then(() => {
                    this.$message.success('修改成功！');
                    this.getData(this.params);
                })
            },
            openDialog(item, isAdd) { // 打开弹窗
                this.form = {...item};
                this.showDialog = true;
                this.isAddFlag = isAdd;
            },
            closeDialog(refName) { // 关闭弹窗
                this.showDialog = false;
                this.initFormData();
                this.$refs[refName].resetFields();
            },
            openAssocDialog(item) { // 打开关联页面弹窗
                this.queryType = "all";
                this.assocSpecial = item;
                this.showAssocDialog = true;
                specialAssocList({special_id: this.assocSpecial.id}).then(res => {
                    res.data.forEach(item => {
                        this.assocSelectionIds.push(item.fk_id);
                    });
                });
                if (item.type === 1 && this.columnList.length === 0) {
                    getArticleColumns().then(res => this.columnList = res.data);
                }
                if (item.type === 2 && this.cateList.length === 0) {
                    getCateCascade().then(res => this.cateList = res.data)
                }
                this.getAssocData(this.assocParam);
            },
            cancel() {
                this.showDialog = false;
            },
            getRowKey(row) {
                return row.id;
            },
            cancelAssoc() {
                this.selectCate = [];
                this.assocParam = {page: 1, page_size:10, ids: [], title: '', cat_id: '', book_name: '', column_id: ''};
                this.assocSelection = [];
                this.assocSelectionIds = [];
                this.$refs.assocTable.clearSelection();
            },
            getSrcList(src) { // 查看图片
                this.srcList = [];
                this.srcList.push(src);
            },
            handleBannerSuccess(res, file) { // 上传图片
                this.form.banner_change = true;
                this.form.banner = res.data.url;
            },
            beforeUpload(file) { // 上传图片
                const isImg = file.type === 'image/jpeg' || file.type === 'image/gif' || file.type === 'image/png';
                const isLt2M = file.size / 1024 / 1024 < 2;
                if (!isImg) this.$message.error('上传头像图片只能是 JPG/gif/jpeg/png 格式!');
                if (!isLt2M) this.$message.error('上传头像图片大小不能超过 2MB!');
                return isImg && isLt2M;
            },
            handleCurrentChange(val) { // 获取页数
                this.params.page = val;
                this.getData(this.params);
            },
            handleSizeChange(val) { // 获取每页条数
                this.params.page_size = val;
                this.getData(this.params);
            },
            handleAssocPageChange(val) { // 获取页数
                this.assocParam.page = val;
                this.getAssocData(this.assocParam);
            },
            handleAssocPageSizeChange(val) { // 获取每页条数
                this.assocParam.page_size = val;
                this.getAssocData(this.assocParam);
            },
            getData(params) {
                this.loading = true;
                getSpecialList(params).then(res => {
                    this.loading = false;
                    const {data, count} = res.data;
                    this.total = count;
                    this.tableData = data;
                }).catch(() => {
                    this.loading = false;
                })
            },
            getAssocData(params) {
                this.assocParam.ids = [];
                if (this.queryType === 'assoc') {
                    if (this.assocSelectionIds.length > 0) {
                        this.assocParam.ids = this.assocSelectionIds;
                    } else {
                        this.assocTotal = 0;
                        this.assocData = [];
                        return;
                    }
                }
                this.assocLoading = true;
                if (this.assocSpecial.type === 1) {
                    params.fields = 'id,title,column_id,column_name,tag,status';
                    getArticleList(params).then(res => {
                        this.assocLoading = false;
                        this.assocData = res.data.data;
                        this.assocTotal = res.data.count;
                        this.renderAssocSelections();
                    }).catch(() => {
                        this.assocLoading = false;
                    })
                } else {
                    params.cat_id = '';
                    let selectCateLength = this.selectCate.length;
                    if (selectCateLength > 0) {
                        params.cat_id = this.selectCate[selectCateLength - 1];
                    }
                    params.fields = 'id,cat_id,book_name,author_id,status';
                    getBookList(params).then(res => {
                        this.assocLoading = false;
                        this.assocData = res.data.data;
                        this.assocTotal = res.data.count;
                        this.renderAssocSelections();
                    }).catch(() => {
                        this.assocLoading = false;
                    })
                }
            },
            renderAssocSelections() {
                this.$nextTick(() => {
                    this.assocData.forEach(row => {
                        if (this.assocSelectionIds.indexOf(row.id) > -1) {
                            this.$refs.assocTable.toggleRowSelection(row, true);
                        }
                    });
                });
            },
            handleAssocSelection(vals) {
                this.assocSelection = vals;
            },
            searchAssoc() {
                this.getAssocData(this.assocParam);
            },
            handleAssoc() {
                if (this.assocSelection.length === 0) {
                    this.$message.warning("请选择关联数据");
                    return;
                }
                let ids = [];
                this.assocSelection.forEach(item => {
                    ids.push(item.id);
                });
                let assocData = {
                    special_id: this.assocSpecial.id,
                    fk_type: this.assocSpecial.type,
                    fk_ids: ids
                };
                specialAssoc(assocData).then(() => {
                    this.showAssocDialog = false;
                    this.$message.success('关联成功!');
                    this.getData(this.params);
                })
            },
            initFormData() { // 初始化数据
                this.form = this.initForm;
            }
        },
    }
</script>

<style lang='less' scoped>

</style>